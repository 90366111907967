import React, { useState } from 'react'
import Img from 'gatsby-image'
import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from 'react-helmet'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'

import Section from '../components/Section'
import Heading from '../components/Heading'
import Button from '../components/Button'

import CheckIcon from '@material-ui/icons/DoneAllOutlined'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  image: {
    display: 'block',
    height: 'auto',
    width: '100%',
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(15),
  },
  h3: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5),
  },
  h4: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  groupTitle: {
    marginTop: theme.spacing(5),
  },
  squareContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  squareTitle: {
    color: '#fff',
    margin: '10px 0 0',
    textTransform: 'uppercase',
  },
  squareIcon: {
    color: '#fff',
    fontSize: '3em',
  },
  button: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
  },
}))

const AttestationsFiscalesRemboursementMutuellePage = ({}) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "tenniswoman1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Helmet
        title={t('AttestationEtRembourdement:head.title')}
        meta={[
          {
            name: 'description',
            content: t('AttestationEtRembourdement:head.description'),
          },
        ]}
      />
      
      <Section bg="#fafcff">

        <Grid container>
            <Grid item xs={12} sm={12}>   
                <Heading
                    title={t('AttestationEtRembourdement:title')}
                    variant="secondary"
                    />
                <p>{t('AttestationEtRembourdement:intro')}</p>
            </Grid>

            <Grid item xs={12} sm={12}>    
                <Typography
                component="h3"
                variant="h5"
                color="secondary"
                className={classes.h3}
                >
                <strong>{t('AttestationEtRembourdement:mutuelle.title')}</strong>
                </Typography>
                <Grid item container xs={12}>
                <Grid item xs={12} sm={12}>
                    <p dangerouslySetInnerHTML={{__html: t(`AttestationEtRembourdement:mutuelle.p1`) }} />
                    <p dangerouslySetInnerHTML={{__html: t(`AttestationEtRembourdement:mutuelle.p2`) }} />
                    <p dangerouslySetInnerHTML={{__html: t(`AttestationEtRembourdement:mutuelle.p3`) }} />
                </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>    
                <Typography
                component="h3"
                variant="h5"
                color="secondary"
                className={classes.h3}
                >
                <strong>{t('AttestationEtRembourdement:attestation.title')}</strong>
                </Typography>
                <Grid item container xs={12}>
                <Grid item xs={12} sm={12}>
                    <p dangerouslySetInnerHTML={{__html: t(`AttestationEtRembourdement:attestation.p1`) }} />
                    <p dangerouslySetInnerHTML={{__html: t(`AttestationEtRembourdement:attestation.p2`) }} />
                    <p dangerouslySetInnerHTML={{__html: t(`AttestationEtRembourdement:attestation.p3`) }} />
                    <p dangerouslySetInnerHTML={{__html: t(`AttestationEtRembourdement:attestation.p4`) }} />
                    <p dangerouslySetInnerHTML={{__html: t(`AttestationEtRembourdement:attestation.p5`) }} />
                </Grid>
                </Grid>
            </Grid>
            
        </Grid>
        
      </Section>
    </React.Fragment>
  )
}

AttestationsFiscalesRemboursementMutuellePage.defaultProps = {}

export default AttestationsFiscalesRemboursementMutuellePage

import React, { Component } from 'react'

import AttestationsFiscalesRemboursementMutuellePage from '../containers/AttestationsFiscalesRemboursementMutuellePage'
import PrimaryLayout from '../components/layouts/PrimaryLayout'
import pagesInfos from '../config/i18n/pagesInfos'

const AttestationEtRembourdement = ({ pageContext }) => {
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={false}
      page={pagesInfos.ComponentAttestationEtRembourdement}
    >
      <AttestationsFiscalesRemboursementMutuellePage />
    </PrimaryLayout>
  )
}

export default AttestationEtRembourdement
